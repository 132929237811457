import Vue from 'vue';
import App from './App.vue';
import router from './router.js';
import store from './store';
import './assets/style/normalize.scss';
import Loading from '@/globalWidget/plugins/loading';
import Ajax from './ajax/index';
import './utils/emojiParse/emoji-position.scss';

import { Tracker } from '@xiaoe/venus/dist/misc/event-tracking';
// 注册自定义指令
import directives from '@/directives';
Vue.use(directives);

// 数据万象 自定义指令
require('@/utils/image-cdn-slim/directive')

import {
    Search,
    ImagePreview,
    Tab,
    Tabs,
    Field,
    Checkbox,
    CheckboxGroup,
    ActionSheet,
    DropdownMenu,
    DropdownItem,
    Toast,
    Button,
    PullRefresh,
    List,
    Uploader,
    Popup,
    Overlay,
    Icon,
    Sticky,
    Dialog,
    Tabbar,
    TabbarItem,
    RadioGroup,
    Radio,
    Form,
    CellGroup
} from 'vant';
import 'vant/lib/index.css';
import './assets/font/font.css';

Vue.use(Search);
Vue.use(ImagePreview);
Vue.use(Tabbar);
Vue.use(TabbarItem);
Vue.use(Tab);
Vue.use(Tabs);
Vue.use(Field);
Vue.use(Checkbox);
Vue.use(CheckboxGroup);
Vue.use(ActionSheet);
Vue.use(DropdownMenu);
Vue.use(DropdownItem);
Vue.use(Ajax);
Vue.use(Button);
Vue.use(PullRefresh);
Vue.use(List);
Vue.use(Popup);
Vue.use(Overlay);
Vue.use(Loading);
Vue.use(Uploader);
Vue.use(Icon);
Vue.use(Sticky);
Vue.use(Radio);
Vue.use(RadioGroup);
Vue.use(Form);
Vue.use(CellGroup);

Vue.prototype.$tracker = Tracker;

Vue.prototype.$toast = Toast;
Vue.prototype.$dialog = Dialog;
Vue.config.productionTip = false;


new Vue({
    router,
    store,
    render: (h) => h(App)
}).$mount('#app');
