<template>
    <div class="app" style="position: relative">
        <van-notice-bar v-if="showTips" class="past-due-tips" left-icon="warning" :text="content" />
        <template v-if="isHasPermission">
            <keep-alive>
                <router-view v-if="$route.meta.keepAlive"></router-view>
            </keep-alive>
            <router-view v-if="!$route.meta.keepAlive"></router-view>
        </template>

        <!-- 默认不展示无权限页面 -->
        <no-permission v-else-if="isLoadedPermission" />
    </div>
</template>

<script>
import { getStaffList } from '@/api';
import { mapState, mapActions, mapMutations } from 'vuex';
import noPermission from '@/components/no-permission';
import { getCookie } from '@/utils/cookies.js';
import { NoticeBar } from 'vant';
export default {
    components: {
        noPermission,
        'van-notice-bar': NoticeBar
    },
    data() {
        return {
            isHasPermission: false,
            agentConfig: null
        };
    },
    computed: {
        ...mapState([
            'rolePermission',
            'isLoadedPermission',
            'expireTime',
            'isBuyWework',
            'remainingSeconds',
            'isLoadExpireTime'
        ]),
        content() {
            if (this.remainingSeconds > 0) {
                return `功能${this.isBuyWework ? '' : '试用'}将于${this.expireTime}到期，请联系管理员${
                    this.isBuyWework ? '续费' : '订购'
                }`;
            }
            return `【企微助手】功能${this.isBuyWework ? '' : '试用'}已到期，请联系管理员${
                this.isBuyWework ? '续费' : '订购'
            }`;
        },
        showTips() {
            const requireLogin = this.$route.matched.some((record) => record.meta.requireLogin);
            if (!requireLogin || !this.isLoadExpireTime) {
                return false;
            } else if (this.remainingSeconds > 0) {
                const oneDayMS = 24 * 60 * 60 * 1000;
                if (this.isBuyWework) {
                    return this.remainingSeconds < 7 * oneDayMS;
                } else {
                    return this.remainingSeconds < 4 * oneDayMS;
                }
            }
            return true;
        }
    },
    watch: {
        $route(to) {
            this.setRolePermission(to);
        }
    },
    created() {
        // 本地开发代理cookie，默认已登录
        const hasLogged = !!getCookie(process.env.VUE_APP_TOKEN) || process.env.NODE_ENV === 'development';

        // 解决引入1.3.2版本后，agentConfig方法不存在的问题
        this.agentConfig = wx.agentConfig;
        // 判断当前是否在微信小程序webView打开
        const isMiniApp = window.__wxjs_environment === 'miniprogram';
        if (isMiniApp) {
            this.SET_IS_IN_MINIPROGRAM(true);
            window.jWeixin = undefined;
            let script = document.createElement('script');
            script.type = 'text/javascript';
            script.src = 'https://res.wx.qq.com/open/js/jweixin-1.3.2.js';
            document.body.appendChild(script);
        }


        // 已登录需要请求业务接口
        if (hasLogged) {
            const withAppId = getCookie('with_app_id');
            if (withAppId && !location.href.includes(withAppId)) {
                location.replace(`${location.origin}?app_id=${withAppId}${location.hash}`);
            } else {
                this.fetchRolePermission(); // 获取员工权限
                this.updateIsConfigSecret(); // 检测是否填写客户联系secret
                this.getExpireInfo(); // 获取店铺过期信息
                this.fetchStaffList(); // 获取员工数据
                this.initWeChatWorkSdk(); // 获取微信签名信息
            }
        } else {
            // 解决链接appwwu8xlmx8953/#/打不到灰度的问题，前端先做跳转，后续运维灰度逻辑调整
            const reg = /\?app_id=(\w*)\/#\//;
            const url = location.href;
            const result = url.match(reg);
            if (result) {
                location.href = url.replace(reg, '?app_id=$1#/');
            }
        }
    },
    methods: {
        ...mapMutations(['setStaffArray', 'setStaffList', 'SET_IS_IN_MINIPROGRAM']),
        ...mapActions(['updateRolePermission', 'updateIsConfigSecret', 'getExpireInfo']),
        async initWeChatWorkSdk() {
            const currentUrl = window.location.href.split('#')[0];
            const response = await this.$ajax('get_sdk_agent_info', {
                url: currentUrl
            });
            const jsApiList = [
                'openUserProfile',
                'onMenuShareWechat',
                'onMenuShareAppMessage',
                'hideMenuItems',
                'previewImage',
                'scanQRCode'
            ];
            const { config, agent_config: agentConfig } = response.data.data;
            await new Promise((resolve, reject) => {
                wx.config({
                    beta: true,
                    debug: false,
                    appId: config.corpid,
                    timestamp: config.timestamp,
                    nonceStr: config.nonceStr,
                    signature: config.signature,
                    jsApiList
                });
                wx.ready(resolve);
                wx.error(reject);
            });

            await new Promise((resolve, reject) => {
                this.agentConfig({
                    corpid: agentConfig.corpid, // 必填，企业微信的corpID
                    agentid: agentConfig.agentid,
                    timestamp: agentConfig.timestamp, // 必填，生成签名的时间戳
                    nonceStr: agentConfig.nonceStr, // 必填，生成签名的随机串
                    signature: agentConfig.signature, // 必填，签名，见 附录-JS-SDK使用权限签名算法
                    jsApiList,
                    success: resolve,
                    fail: reject
                });
            });

            this.$store.commit('setBindWWOpData', true);
        },
        async fetchRolePermission() {
            await this.updateRolePermission();
            this.setRolePermission(this.$route);
        },
        // 设置员工权限
        setRolePermission(route) {
            // 设置角色访问权限
            const accessPermission = this.rolePermission.access_permission;
            this.isHasPermission = route.matched.some((record) => {
                const permission = record.meta.rolePermission;
                // 未设置权限id的页面可以正常访问
                if (permission === 0) return true;
                if (!accessPermission.length) return false;
                return accessPermission.includes(permission);
            });
        },
        // 获取员工数据
        fetchStaffList() {
            getStaffList({})
                .then(({ data }) => {
                    this.setStaffArray(data);
                    let staff_map = {};
                    data.forEach((item) => {
                        staff_map[item.user_id] = item['NAME'].trim();
                    });
                    this.setStaffList(staff_map);
                })
                .catch((err) => {
                    this.$toast(err);
                });
        }
    }
};
</script>
<style lang="scss">
.van-search {
    width: 100%;
    .van-cell {
        align-items: center;
    }
    .van-search__content {
        height: 0.8rem;
        background: #f5f5f5;
        border-radius: 0.4rem;
    }
}
.fixed {
    margin: auto;
    max-width: 480px;
}
.app {
    background: #f5f6f9;
    min-height: 100%;
    position: relative;
}
.past-due-tips {
    background-color: #ffedeb !important;
    color: #ff2929 !important;
    font-size: 12px !important;
}
</style>
