import Http from '@xiaoe/venus/dist/net/request';
// import router from '@/router'

Http.interceptors.response.use(
    (res) => {
        return res;
    },
    (err) => {
        // 账号停用
        if (err.code === 10002) {
            // router.replace({
            //     name: 'errorPage',
            //     query: {
            //         error: err.msg
            //     }
            // });
        }
        console.error(err);
        return Promise.reject(err);
    }
);

export default Http;
