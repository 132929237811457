import Vue from "vue";
import "./loading.css";

export default Vue.directive("loading", {
  bind(el, binding) {
    const wrapperDiv = document.createElement("div");
    wrapperDiv.className = "custom-loading-wrapper";
    const tempDiv = document.createElement("div");
    tempDiv.className = "custom-loading";
    const tempBox = document.createElement("div");
    tempBox.className = "custom-loading-box";
    const round = document.createElement("div");
    round.className = "custom-loading-round";
    tempBox.appendChild(round);
    const loadingText = document.createElement("p");
    loadingText.className = "loadingText";
    loadingText.innerHTML = "加载中";
    tempBox.appendChild(loadingText);
    tempDiv.appendChild(tempBox);
    wrapperDiv.appendChild(tempDiv);
    el.loadingElement = wrapperDiv;
    if (binding.value) {
      el.appendChild(wrapperDiv);
    }

    setSize(el.loadingElement);
    document.querySelector(".base_right") &&
      document
        .querySelector(".base_right")
        .addEventListener("scroll", function () {
          setSize(el.loadingElement);
        });
    window.onresize = () => {
      setSize(el.loadingElement);
    };
  },
  update: (el, binding) => {
    if (binding.value) {
      if (el.loadingElement.parentNode === null) {
        el.appendChild(el.loadingElement);
      }
    } else {
      if (el === el.loadingElement.parentNode) {
        el.removeChild(el.loadingElement);
      }
    }

    setSize(el.loadingElement);
    document.querySelector(".base_right") &&
      document
        .querySelector(".base_right")
        .addEventListener("scroll", function () {
          setSize(el.loadingElement);
        });
    window.onresize = () => {
      setSize(el.loadingElement);
    };
  },
  unbind: (el) => {
    if (el.loadingElement.parentNode === el) {
      el.removeChild(el.loadingElement);
    }
    el.loadingElement = null;
  },
});

function setSize(el) {
  // Vue.nextTick(()=>{
  //   if (document.querySelector('.base_content')) {
  //     el.style.left = document.querySelector('.base_content').getBoundingClientRect().left + 20 + 'px';
  //     let curRight = document.querySelector('.base_content').getBoundingClientRect().right;
  //     if(curRight <= 1280) {
  //       el.style.right = '0px';
  //     } else {
  //       el.style.right = '30px';
  //     }
  //   }
  //   if (document.querySelector('.nav-top')) {
  //     if(document.querySelector('.nav-top').getBoundingClientRect().bottom <0) {
  //       el.style.top = '0px';
  //     } else {
  //       el.style.top = document.querySelector('.nav-top').getBoundingClientRect().bottom + 'px';
  //     }
  //   }
  // })
}
