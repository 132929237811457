import { getCookie } from '@/utils/cookies';
import { Dialog } from 'vant';
import store from '@/store';
export const formatTime = (timestamp, type) => {
    let time = new Date(timestamp);
    let y = time.getFullYear();
    let m = time.getMonth() + 1;
    let d = time.getDate();
    let h = time.getHours();
    let mm = time.getMinutes();
    let s = time.getSeconds();
    switch (type) {
        case 'year':
            return addM(m) + '-' + addM(d) + ' ' + addM(h) + ':' + addM(mm);
        case 'seconds':
            return y + '-' + addM(m) + '-' + addM(d) + ' ' + addM(h) + ':' + addM(mm);
        default:
            return y + '-' + addM(m) + '-' + addM(d) + ' ' + addM(h) + ':' + addM(mm) + ':' + addM(s);
    }
};
function addM(m) {
    return m < 10 ? '0' + m : m;
}

// 函数防抖
export const debounce = (func, wait = 300) => {
    let timer = null;
    return function (...rest) {
        const self = this;
        clearTimeout(timer);
        timer = setTimeout(() => {
            func.apply(self, rest);
        }, wait);
    };
};

export const tools = {
    getUserId: () => localStorage.getItem('userId'),
    getAppId: () => getCookie('with_app_id')
};

// 函数节流
export const throttle = (fn, wait = 300, isImmediate = false) => {
    let timerId = null;
    // 立即执行
    if (isImmediate) {
        return function () {
            if (timerId) {
                return;
            }
            fn.apply(this, arguments);
            timerId = setTimeout(() => {
                timerId = null;
            }, wait);
        };
    }
    // 非立即执行
    return function () {
        if (timerId) {
            return;
        }
        timerId = setTimeout(() => {
            fn.apply(this, arguments);
            timerId = null;
        }, wait);
    };
};

export const openExpireTimeDialog = () => {
    const { managers = [], isBuyWework } = store.state;
    const managersOpenData = managers.map((item) => {
        return `<span class="open-data-name"><ww-open-data type="userName" openid="${item}" /></span>`;
    });
    const nameStr = managers?.length > 0 ? managersOpenData.join('、') : '管理员';
    Dialog.alert({
        title: '到期提醒',
        message: `【企微助手】功能${isBuyWework ? '' : '试用'}已到期，请联系${nameStr}前往【知识店铺-服务订购】${
            isBuyWework ? '续费' : '订购'
        }`,
        theme: 'round-button',
        confirmButtonColor: '#1472FF',
        confirmButtonText: '我知道了',
        className: 'permits_service'
    }).then(() => {});
};
