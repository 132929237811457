import { AVATAR_DEFAULT_IMG } from '@/constant';

const handlerDefaultAvatar = function (el) {
    if (el.nodeName === 'IMG') {
        if (el.getAttribute('src')) {
            el.onerror = function () {
                el.src = AVATAR_DEFAULT_IMG;
                el.onerror = null;
            };
        } else {
            el.src = AVATAR_DEFAULT_IMG;
        }
    }
}

const defaultAvatar = {
    inserted: function (el) {
        handlerDefaultAvatar(el);
    },
    componentUpdated: function (el) {
        handlerDefaultAvatar(el);
    }
};

export default defaultAvatar;
