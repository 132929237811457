import Vue from 'vue';
import VueRouter from 'vue-router';
import { getCookie } from '@/utils/cookies.js';
import * as Tracker from '@/utils/sensors-report';

Vue.use(VueRouter);
const routerPush = VueRouter.prototype.push;
VueRouter.prototype.push = function push(location) {
    return routerPush.call(this, location).catch((error) => error);
};
const router = new VueRouter({
    routes: [
        {
            path: '/',
            redirect: '/home'
        },
        {
            path: '*',
            name: '404',
            meta: {
                title: '小鹅通·企业微助手',
                rolePermission: 0
            },
            component: () => import(/* webpackChunkName: "errorPage" */ './views/error.vue')
        },
        {
            path: '/home',
            name: 'home',
            meta: {
                title: '小鹅通·企业微助手',
                keepAlive: true,
                requireLogin: true,
                rolePermission: 0
            },
            component: () => import(/* webpackChunkName: "home" */ './views/Home.vue')
        },
        {
            path: '/error_page',
            name: 'errorPage',
            meta: {
                title: '小鹅通·企业微助手',
                rolePermission: 0
            },
            component: () => import(/* webpackChunkName: "errorPage" */ './views/error.vue')
        },
        //引导配置页
        {
            path: '/setting_page',
            name: 'settingPage',
            meta: {
                title: '小鹅通·企业微助手',
                requireLogin: true,
                rolePermission: 0
            },
            component: () => import(/* webpackChunkName: "settingPage" */ './views/setting.vue')
        },
        //编辑手机页
        {
            path: '/edit_info',
            name: 'editInfo',
            meta: {
                title: '小鹅通·企业微助手',
                requireLogin: true,
                rolePermission: 0
            },
            component: () => import(/* webpackChunkName: "edit-info" */ './views/edit-info.vue')
        },
        {
            path: '/userlist',
            name: 'userlist',
            meta: {
                title: '客户列表',
                requireLogin: true,
                rolePermission: 301,
                pv: {
                    page_id: 'scrm_h5_my customers',
                    page_name: 'SCRM_员工端_客户列表'
                }
            },
            component: () => import(/* webpackChunkName: "userlist" */ './views/userList/index.vue')
        },
        {
            path: '/customer_contact',
            name: 'customer_contact',
            meta: {
                title: '新建群发',
                keepAlive: true,
                requireLogin: true,
                rolePermission: 31,
                pv: {
                    page_id: 'scrm_h5_customer_contact',
                    page_name: 'SCRM_员工端_客户群发'
                }
            },
            component: () => import(/* webpackChunkName: "customer_contact" */ './views/customerContact/index.vue')
        },
        {
            path: '/customer_contact/addStaff',
            name: 'addStaff',
            meta: {
                title: '使用员工',
                requireLogin: true,
                rolePermission: 31
            },
            component: () => import(/* webpackChunkName: "addStaff" */ './views/customerContact/staff.vue')
        },
        {
            path: '/customer_contact/addWebsite',
            name: 'addWebsite',
            meta: {
                title: '添加网页',
                requireLogin: true,
                rolePermission: 31
            },
            component: () => import(/* webpackChunkName: "addWebsite" */ './views/customerContact/website.vue')
        },
        {
            path: '/customer_contact/addForm',
            name: 'addForm',
            meta: {
                title: '添加表单',
                requireLogin: true,
                rolePermission: 31
            },
            component: () => import(/* webpackChunkName: "addForm" */ './views/customerContact/formList.vue')
        },
        {
            path: '/customer_contact/addLabel',
            name: 'addLabel',
            meta: {
                title: '选择标签',
                requireLogin: true,
                rolePermission: 31
            },
            component: () => import(/* webpackChunkName: "addLabel" */ './views/customerContact/labelList.vue')
        },
        {
            path: '/channel_liveCode',
            name: 'channel_liveCode',
            meta: {
                title: '渠道活码',
                keepAlive: true,
                requireLogin: true,
                rolePermission: 101
            },
            component: () => import(/* webpackChunkName: "channelLiveCode" */ './views/channel_liveCode/index.vue')
        },
        {
            path: '/channel_liveCode/details',
            name: 'codeDetails',
            meta: {
                title: '渠道活码',
                requireLogin: true,
                rolePermission: 101,
                pv: {
                    page_id: 'scrm_h5_traffic_channel',
                    page_name: 'SCRM_员工端_渠道活码'
                }
            },
            component: () => {
                return import(/* webpackChunkName: "codeDetails" */ './views/channel_liveCode/codeDetails.vue');
            }
        },
        {
            path: '/group_liveCode',
            name: 'group_liveCode',
            meta: {
                title: '群组活码',
                keepAlive: true,
                requireLogin: true,
                rolePermission: 111,
                pv: {
                    page_id: 'scrm_h5_traffic_group',
                    page_name: 'SCRM_员工端_群组活码'
                }
            },
            component: () => import(/* webpackChunkName: "groupLiveCode" */ './views/group_liveCode/index.vue')
        },
        {
            path: '/groupWelcome',
            name: 'groupWelcome',
            meta: {
                title: '入群欢迎语详情',
                keepAlive: true,
                requireLogin: true,
                rolePermission: 0
            },
            component: () => import(/* webpackChunkName: "groupWelcome" */ './views/groupWelcome/index.vue')
        },
        {
            path: '/guideGroup',
            name: 'guideGroup',
            meta: {
                title: '入群欢迎语指导',
                keepAlive: true,
                requireLogin: true,
                rolePermission: 0
            },
            component: () => import(/* webpackChunkName: "guideGroup" */ './views/guideGroup/index.vue')
        },
        {
            path: '/customer_statistics',
            name: 'customerStatistics',
            meta: {
                title: '客户统计',
                requireLogin: true,
                rolePermission: 33,
                pv: {
                    page_id: 'scrm_h5_customer_statistics',
                    page_name: 'SCRM_员工端_客户统计'
                }
            },
            component: () => import(/* webpackChunkName: "customerStatistics" */ './views/customerStatistics/index.vue')
        },
        {
            path: '/poster_list',
            name: 'posterList',
            meta: {
                title: '海报素材',
                requireLogin: true,
                rolePermission: 0,
                pv: {
                    page_id: 'scrm_h5_poster_material',
                    page_name: 'SCRM_员工端_海报素材'
                }
            },
            component: () => import(/* webpackChunkName: "posterList" */ './views/posterList/index.vue')
        },
        {
            path: '/poster_edit',
            name: 'posterEdit',
            meta: {
                title: '编辑海报',
                requireLogin: true,
                rolePermission: 0
            },
            component: () => import(/* webpackChunkName: "posterEdit" */ './views/posterList/edit.vue')
        },
        {
            path: '/create_success',
            name: 'createSuccess',
            meta: {
                title: '创建成功',
                requireLogin: true,
                rolePermission: 0
            },
            component: () => import(/* webpackChunkName: "createSuccess" */ './views/createSuccess.vue')
        },
        {
            path: '/my_card',
            name: 'myCard',
            meta: {
                title: '我的名片',
                requireLogin: true,
                rolePermission: 0,
                pv: {
                    page_id: 'scrm_h5_business_card',
                    page_name: 'SCRM_员工端_企业名片'
                }
            },
            component: () => import(/* webpackChunkName: "myCard" */ './views/myCard/A.vue')
        },
        {
            path: '/edit_card',
            name: 'editCard',
            meta: {
                title: '编辑名片',
                requireLogin: true,
                rolePermission: 0
            },
            component: () => import(/* webpackChunkName: "editCard" */ './views/editCard/A.vue')
        },
        {
            path: '/marketing/staff_task/:id',
            name: 'staffTask',
            meta: {
                title: '任务详情',
                requireLogin: true,
                rolePermission: 0
            },
            component: () => import(/* webpackChunkName: "staffTask" */ './views/staffTask/page.vue')
        },
        {
            path: '/staff_promotion',
            name: 'staffPromotion',
            meta: {
                title: '推广中心',
                requireLogin: true,
                rolePermission: 0
            },
            component: () => import(/* webpackChunkName: "staffPromotion" */ './views/staffPromotion/page/index.vue')
        },
        {
            path: '/staff_promotion/promotion_activity',
            name: 'promotionActivity',
            meta: {
                title: '活动列表',
                requireLogin: true,
                rolePermission: 0
            },
            component: () =>
                import(/* webpackChunkName: "promotionActivity" */ './views/staffPromotion/page/activityList/index.vue')
        },
        {
            path: '/staff_promotion/customer_list',
            name: 'customerList',
            meta: {
                keepAlive: true,
                title: '客户列表',
                requireLogin: true,
                rolePermission: 0
            },
            component: () =>
                import(/* webpackChunkName: "customerList" */ './views/staffPromotion/page/customerList/index.vue')
        },
        {
            path: '/staff_promotion/customer_detail',
            name: 'customerDetail',
            meta: {
                title: '客户详情',
                requireLogin: true,
                rolePermission: 0
            },
            component: () =>
                import(/* webpackChunkName: "customerDetail" */ './views/staffPromotion/page/customerDetail/index.vue')
        },
        {
            path: '/staff_promotion/customer_search',
            name: 'customerSearch',
            meta: {
                title: '搜索客户',
                requireLogin: true,
                rolePermission: 0
            },
            component: () =>
                import(
                    /* webpackChunkName: "customerSearch" */ './views/staffPromotion/page/customerList/components/customerSearch.vue'
                )
        },
        {
            path: '/staff_promotion/activity_detail/:id/:coupon_id?',
            name: 'promotionDetail',
            meta: {
                title: '活动详情',
                requireLogin: true,
                rolePermission: 0
            },
            query: {
                app_id: '123123'
            },
            component: () =>
                import(/* webpackChunkName: "promotionDetail" */ './views/staffPromotion/page/activityDetail/index.vue')
        },
        {
            path: '/staff_promotion/promotion_tips',
            name: 'promotionTips',
            meta: {
                title: '',
                requireLogin: true,
                rolePermission: 0
            },
            component: () =>
                import(/* webpackChunkName: "promotionTips" */ './views/staffPromotion/components/promotionTips.vue')
        },
        {
            path: '/no_permission_page',
            name: 'noPermissionPage',
            meta: {
                title: '',
                requireLogin: true,
                rolePermission: 0
            },
            component: () =>
                import(/* webpackChunkName: "promotionTips" */ './views/staffPromotion/components/noPermissionPage.vue')
        },
        // 客户列表c端消息路由，带有推广中心按钮
        {
            path: '/staff_promotion/customer_list_btn',
            name: 'customerListBtn',
            meta: {
                title: '客户列表',
                requireLogin: true,
                rolePermission: 0
            },
            component: () =>
                import(/* webpackChunkName: "customerList" */ './views/staffPromotion/page/customerList/index.vue')
        },
        // 活动管理c端消息路由，带有推广中心按钮
        {
            path: '/staff_promotion/activity_detail_btn/:id',
            name: 'activityDetailBtn',
            meta: {
                title: '活动详情',
                requireLogin: true,
                rolePermission: 0
            },
            component: () =>
                import(
                    /* webpackChunkName: "activityDetailBtn" */ './views/staffPromotion/page/activityDetail/index.vue'
                )
        },
        {
            path: '/staff_promotion/invite_posters',
            name: 'invitePosters',
            meta: {
                title: '推广员邀请',
                requireLogin: true,
                rolePermission: 0
            },
            component: () =>
                import(/* webpackChunkName: "invitePosters" */ './views/staffPromotion/page/invitePosters/index.vue')
        },
        // 渠道活码中间页
        {
            path: '/channel_liveCode/middle_page',
            name: 'channelMiddlePage',
            meta: {
                title: '',
                rolePermission: 0
            },
            component: () =>
                import(/* webpackChunkName: "channelMiddlePage" */ './views/channel_liveCode/channelCodeMiddlePage.vue')
        },
        {
            path: '/channel_wx_customer',
            name: 'channel_wx_customer',
            meta: {
                title: '',
                rolePermission: 0,
                pv: {
                    page_id: 'scrm_h5_channel_wx_customer',
                    page_name: 'SCRM_员工端_微信客服'
                }
            },
            component: () => import(/* webpackChunkName: "channelMiddlePage" */ './views/wx_customer/index.vue')
        },
        {
            path: '/lead_page',
            name: 'leadPage',
            meta: {
                title: '小鹅通·企微助手',
                requireLogin: false,
                rolePermission: 0
            },
            component: () => import(/* webpackChunkName: "leadPage" */ './views/leadPage/index.vue')
        },
        {
            path: '/staff_promotion/distributeSeting',
            name: 'distributeSeting',
            meta: {
                title: '推广引流设置',
                requireLogin: true,
                rolePermission: 0
            },
            component: () => import('./views/staffPromotion/page/distributeSeting/index.vue')
        },
        {
            path: '/upgrade_auth',
            name: 'upgradeAuth',
            meta: {
                title: '小鹅通·企微助手',
                requireLogin: true,
                rolePermission: 0
            },
            component: () => import(/* webpackChunkName: "upgradeAuth" */ './views/upgradeAuth/index.vue')
        },
        // 渠道活码异常列表页
        {
            path: '/channel/abnormalReminder',
            name: 'abnormalReminder',
            meta: {
                title: '异常列表',
                requireLogin: true,
                rolePermission: 0
            },
            component: () => import(/* webpackChunkName: "channelMiddlePage" */ './views/abnormalReminder/index.vue')
        }
    ]
});

router.beforeEach((to, from, next) => {
    to.matched.some((record) => {
        document.title = record.meta.title || ' ';
    });
    const hasLogged = !!getCookie(process.env.VUE_APP_TOKEN);
    // 推广邀请页 query.is_error 特殊逻辑 不需要登录
    const invitePostersError = to.name === 'invitePosters' && to.query.is_error;
    const requireLogin = to.matched.some((record) => record.meta.requireLogin);
    const clientType = /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent)
        ? 'Mobile'
        : 'Desktop';
    const isComWx = /wxwork/i.test(navigator.userAgent); // 是否企业微信
    if (hasLogged || invitePostersError || !requireLogin) {
        if (!(clientType === 'Desktop' && to.fullPath === '/home' && isComWx)) {
            next();
            return;
        }
        //因为销售CRM和企微助手合并 在工作台pc时 默认进入销售CRM PC端 所以进入首页有登录态需要check_login
        //判断是否有销售CRM权限
    }
    Vue.prototype
        .$ajax('check_login', {
            redirect_state: to.fullPath,
            login_channel: from.fullPath === '/' || from.fullPath === '' ? 'common' : '',
            client_type: clientType === 'Desktop' && isComWx ? 0 : 1 //客户端类型:0-pc,1-mobile
        })
        .then((res) => {
            const { code, data } = res.data;
            if (code == 0) {
                next();
            } else if (code == 401) {
                window.location.replace(data.redirect_uri);
            } else {
                next('/error_page?error=登录失败，请重试或联系客服');
            }
        })
        .catch(() => {
            next('/error_page?error=登录失败，请重试或联系客服');
        });
});

router.afterEach((to) => {
    const pv = to.meta.pv;
    if (pv) {
        const appId = getCookie('with_app_id');
        const weworkUserId = localStorage.getItem('userId');

        Tracker.pv({
            l_program: 'scrm_h5',
            app_id: appId,
            wework_user_id: weworkUserId,
            ...pv
        });
    }
});

export default router;
