// import Http from '@xiaoe/venus/dist/net/request';
import Http from '@/utils/request';

const realUrl = function (url) {
    const startStr = '/forward_admin';
    return url.startsWith(startStr) ? url : `${startStr}${url}`;
};

// 获取角色权限列表
export const fetchRolePermission = () => {
    return Http.get(realUrl('/wework/wework_manage/user_access_pmsn'))
        .then((data) => {
            return data;
        })
        .catch((error) => {
            console.log(error);
            throw error.msg || '未获取到角色权限信息';
        });
};

// 检测客户联系secret是否填写正确
export const checkedExternalSecret = () => {
    return Http.get(realUrl('/wework/setting/check_external_secret'))
        .then((data) => {
            return data;
        })
        .catch((error) => {
            console.log(error);
            throw error.msg || '获取外部联系人secret失败';
        });
};

// 获取店铺过期信息
export const getShopExpiredInfo = () => {
    return Http.get(realUrl('/wework/bind_shop/get_shop_equity_info'))
        .then((data) => {
            return data;
        })
        .catch((error) => {
            console.log(error);
            throw error.msg || '获取店铺过期信息失败';
        });
};

// 获取员工数据
export const getStaffList = (params = {}, config = {}) => {
    return Http.get(realUrl('/wework/contact_card/user_search'), { params, ...config })
        .then((data) => {
            return data;
        })
        .catch((error) => {
            console.log(error);
            throw error.msg || '获取员工数据失败';
        });
};

export const loginOut = () => {
    return Http.get(realUrl('/logout_for_transmit_request'))
        .then((data) => {
            return data;
        })
        .catch((error) => {
            throw error.msg || '操作失败';
        });
};

// 获取店铺过期信息
export const getExpireInfo = () => {
    return Http.get(realUrl('/wework/setting/get_wework_version_expire_info'));
};

// 获取管理员列表
export const getManagerList = (params) => {
    return Http.post(realUrl('/wework/staff/lists'), params);
};

/**
 * 保存员工信息
 * @param { phone } 手机号码
 */
export const saveStaffInfo = (params) => {
    return Http.post(realUrl('/wework/person_card/save_info'), params);
};

// 获取批量加客户的链接
export const getBatchAddCustomerLink = () => {
    return Http.get(realUrl('/wework/customer_import/get_batch_add_customer_link'));
};
