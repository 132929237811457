// 万象图片瘦身函数
import { replaceCDN, replaceAllImg } from '@xiaoe/js-tools';

export default (el, { value }) => {
    let originUrl = el.src;
    let config = value || '?imageView2/2/w/1025/a/100';

    if (originUrl && !originUrl.includes('localhost')) {
        const url = replaceCDN(originUrl, `?imageView2${config}`, true);

        // 对图片元素的src进行赋值
        el.setAttribute('src', url);
    }
};

// 替换链接directives
export const imageCdnSlimDirectives = (el, { value }) => {
    let originUrl = el.src;
    let config = value || '?imageView2/2/w/1025/a/100';

    if (originUrl && !originUrl.includes('localhost')) {
        const url = getImageCdnSlimUrl(originUrl, config);

        // 对图片元素的src进行赋值
        el.setAttribute('src', url);
    }
};

// 拼接链接的函数 可以用于JSX
export const getImageCdnSlimUrl = (originUrl, config = '?imageView2/2/w/1025/a/100') => replaceCDN(originUrl, config, true);

// 处理富文本
export const getImageCdnSlimRichTextContent = richText => replaceAllImg(richText,false);