/**
 * 异步加载ajax请求
 */
import HTTP from '@xiaoe/js-tools';
import config from './config/config.js';

export default {
    install(Vue) {
        Vue.prototype.$ajax = (apiName, param = {}) => {
            return new Promise((resolve, reject) => {
                if (!config[apiName]) {
                    resolve({
                        message: `不存在该接口: ${apiName}`
                    });
                }
                let url = `${config[apiName].url}`;
                let params = param;
                let data = {
                    method: 'post',
                    pack: '',
                    params,
                    url,
                    timeout: 20000
                };

                if (config[apiName].method) {
                    data.method = config[apiName].method.toLowerCase();
                }
                HTTP.request(data)
                    .then((response) => {
                        // 服务请求成功
                        resolve({
                            data: response || ''
                        });
                    })
                    .catch((error) => {
                        reject({
                            status: '',
                            message: error,
                            data: null
                        });
                    });
            });
        };
    }
};
