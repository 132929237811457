/**
 * 基础配置
 * { conf } 接口配置
 */
const origin = `/forward_admin`; //主干现网接口地址

const list = {
    detail_welcome: {
        url: '/wework/welcome/detail_welcome',
        method: 'post'
    },
    get_sdk_info: {
        url: '/wework/auth/get_sdk_info',
        method: 'get'
    },
    get_shop_info: {
        url: '/get_shop_info',
        method: 'get'
    },
    get_role_permission: {
        url: '/wework/wework_manage/user_access_pmsn',
        method: 'get'
    },
    get_user_behavior_data: {
        url: '/wework/statistics/get_user_behavior_data',
        method: 'get'
    },
    get_chart_data: {
        url: '/wework/statistics/get_user_behavior_data/chart_data',
        method: 'get'
    },
    card_list: {
        url: '/wework/contact_card/card_list',
        method: 'get'
    },
    group_card_list: {
        url: '/wework/group/group_card_list',
        method: 'get'
    },
    check_login: {
        url: '/wework_auth/check_b_web_login',
        method: 'get'
    },
    group_qr_code_list: {
        url: '/wework/group/group_qr_code_list',
        method: 'post'
    },
    get_upload_sign: {
        url: '/getUploadSign?sign_type=appSign',
        method: 'get'
    },
    get_upload_sign_once: {
        url: '/getUploadSign?sign_type=appSign_once&path=',
        method: 'get'
    },
    get_staff_list: {
        url: '/wework/wework_manage/manage_list',
        method: 'post'
    },
    search_staff: {
        url: '/wework/staff/search',
        method: 'get'
    },
    get_apply_info: {
        url: '/wework/setting/get_apply_info',
        method: 'get'
    },
    check_external_secret: {
        url: '/wework/setting/check_external_secret',
        method: 'get'
    },
    get_shop_expired_info: {
        url: '/get_shop_expired_info',
        method: 'get'
    },
    custom_search: {
        url: '/wework/custom/custom_search',
        method: 'post'
    },
    get_user_role: {
        url: '/wework/wework_manage/get_user_role',
        method: 'get'
    },
    tag_list: {
        url: '/wework/custom/tag_list',
        method: 'get'
    },
    edit_group_news: {
        url: '/wework/custom/edit_group_news',
        method: 'post'
    },
    get_pop_window_info: {
        url: '/get_pop_window_info',
        method: 'post'
    },
    get_form_list: {
        url: '/evaluation_work/customize_form/form_list',
        method: 'post'
    },
    get_sdk_agent_info: {
        url: '/wework/auth/get_sdk_agent_info',
        method: 'get'
    },
    user_search: {
        url: '/wework/contact_card/user_search',
        method: 'get'
    },
    is_qw_try_version: {
        url: '/is_qw_try_version',
        method: 'post'
    },
    bills_type: {
        url: '/wework/bills_management/bills_type',
        method: 'get'
    },
    bills_list: {
        url: '/wework/bills_management/list',
        method: 'get'
    },
    sheet_list: {
        url: '/wework/bills_management/link_list',
        method: 'get'
    },
    get_lead_link_scheme: {
        url: '/wework_toolbar/xe.lead_link.scheme.get/1.0.0',
        method: 'post'
    },
    add_lead_link_uv: {
        url: '/wework_toolbar/xe.lead_link.add.view/1.0.0',
        method: 'post'
    },
    add_group_card_uv: {
        url: '/wework_toolbar/xe.group.card.view.add/1.0.0',
        method: 'post'
    },
    add_wx_group_uv: {
        url: '/wework_toolbar/xe.wxcode.view.add/1.0.0',
        method: 'post'
    },
    get_wx_group_id: {
        url: '/wework_toolbar/contactCustomer',
        method: 'post'
    },
    get_lead_link_detail: {
        url: '/wework_toolbar/xe.lead_link.scheme.detail/1.0.0',
        method: 'post'
    },
    get_activity_page_config: {
        url: '/wework_toolbar/activeDescription',
        method: 'post'
    },
    get_card_detail: {
        // 获取名片详情
        url: '/wework/person_card/detail',
        method: 'post'
    },
    save_card: {
        // 名片基础信息保存
        url: '/wework/person_card/save',
        method: 'post'
    },
    save_card_style: {
        // 名片头像-样式保存
        url: '/wework/person_card/save-style',
        method: 'post'
    },
    get_custom_total: {
        url: '/wework/custom/custom_search_total',
        method: 'post'
    },
    get_qwuser_info: {
        url: '/wework/person_card/detail',
        method: 'post'
    },
    get_distribute_seting: {
        // 获取推广设置的信息
        url: '/wework_distribute/xe.c.distribute.staff.info.detail/1.0.0',
        method: 'post'
    },
    save_distribute_seting: {
        // 保存推广信息设置
        url: '/wework_distribute/xe.c.distribute.staff.info.save/1.0.0',
        method: 'post'
    },
    set_distribute_type: {
        // 推广信息展示设置
        url: '/wework_distribute/xe.c.distribute.staff.info.select/1.0.0',
        method: 'post'
    },
    get_activity_invite_rank: {
        // 获取活动访客排行数据
        url: '/wework_distribute/xe.c.activity.invite.ranking/1.0.0',
        method: 'post'
    },
    get_activity_business_rank: {
        url: '/wework_distribute/xe.c.activity.merchant.ranking/1.0.0',
        method: 'post'
    },
    get_main_invite_rank: {
        url: '/wework_distribute/xe.c.distribute.rank.visitor.main/1.0.0',
        method: 'post'
    },
    get_main_business_rank: {
        url: '/wework_distribute/xe.c.distribute.rank.merchant.main/1.0.0',
        method: 'post'
    },
    get_resource_code_info: {
        url: '/scrm.clues.manage/rule.code/get.resource.code.info',
        method: 'post'
    }
};

let serverList = {};
Object.keys(list).forEach((key) => {
    let item = list[key];
    serverList[key] = {
        url: origin + item.url,
        method: item.method,
        param: {}
    };
});

export default serverList;
