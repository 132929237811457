/*
 * @Author: your name
 * @Date: 2021-04-23 23:28:52
 * @LastEditTime: 2021-04-24 16:08:39
 * @LastEditors: your name
 * @Description: In User Settings Edit
 * @FilePath: \scrm-admin-mobile-fe\src\store\index.js
 */
import Vue from 'vue';
import Vuex from 'vuex';
Vue.use(Vuex);
import { fetchRolePermission, checkedExternalSecret, getExpireInfo, getManagerList } from '@/api';

export default new Vuex.Store({
    state: {
        userList: [],
        formList: {},
        webpage: {},
        tagList: [],
        tagName: [],
        staff_array: [],
        staff_list: {},
        businessDetail: {},
        /**
         * 是否有自建应用，控制Home页入口[名片、海报]
         * 0-否，1-是
         */
        is_self_build: 0,
        /**
         * 是否是推广员
         * 0-否，1-是
         */
        isStaffPromoter: 0,
        /*
         *是否可用员工
         *可用状态 0.可用 2.不可用
         */
        isAvailableStaff: 2,
        bindWWOpData: false,
        rolePermission: {
            access_permission: [],
            data_permission: {
                type: 0,
                dept_ids: []
            },
            super_manage: {}
        }, // 角色权限列表
        isLoadedPermission: false,
        isInMiniprogram: false,
        isConfigedSecret: false, //是否已配置外部联系人密钥
        isSetToDetail: false, // 是否 推广信息设置页 保存成功后跳到的 活动详情页, 用于判断是否展示设置弹窗
        remainingSeconds: 0, // 店铺剩余时间 秒
        expireTime: '',
        isLoadExpireTime: false,
        isBuyWework: false,
        managers: [],
        sales_crm_user: null
    },
    getters: {
        getBusinessDetail(state) {
            return state.businessDetail;
        }
    },
    mutations: {
        SET_IS_IN_MINIPROGRAM(state, data) {
            state.isInMiniprogram = data;
        },
        setBusinessDetail(state, data) {
            state.businessDetail = data;
            console.log('tag', state.businessDetail);
        },
        setUserList(state, data) {
            state.userList = data;
        },
        setFormList(state, data) {
            state.formList = data;
        },
        setWebpageData(state, data) {
            state.webpage = data;
        },
        setTagList(state, data) {
            state.tagList = data;
        },
        setTagName(state, data) {
            state.tagName = data;
        },
        setStaffArray(state, data) {
            state.staff_array = data;
        },
        setStaffList(state, data) {
            state.staff_list = data;
        },
        setSelfBuild(state, data) {
            state.is_self_build = data;
        },
        setStaffPromoter(state, data) {
            state.isStaffPromoter = data;
        },
        setAvailableStaff(state, data) {
            state.isAvailableStaff = data;
        },
        setBindWWOpData(state, data) {
            state.bindWWOpData = data;
        },
        setRolePermission(state, permission) {
            state.rolePermission = permission;
        },
        setIsLoadedPermission(state, isLoadedPermission) {
            state.isLoadedPermission = isLoadedPermission;
        },
        setIsConfigedSecret(state, isConfigedSecret) {
            state.isConfigedSecret = isConfigedSecret;
        },
        setIsSetToDetail(state, isSetToDetail) {
            state.isSetToDetail = isSetToDetail;
        },
        setRemainingSeconds(state, data) {
            state.remainingSeconds = data;
        },
        setExpireTime: (state, data) => {
            state.expireTime = data;
        },
        setIsLoadExpireTime: (state, bool) => {
            state.isLoadExpireTime = bool;
        },
        setIsBuyWework: (state, data) => {
            state.isBuyWework = data;
        },
        setManager: (state, data) => {
            state.managers = data;
        },
        setSalesCrmUser: (state, data) => {
            state.sales_crm_user = data;
        }
    },
    actions: {
        updateRolePermission({ commit }) {
            return fetchRolePermission()
                .then(({ data }) => {
                    commit('setRolePermission', data);
                })
                .catch(() => {})
                .finally(() => {
                    commit('setIsLoadedPermission', true);
                });
        },
        updateIsConfigSecret({ commit }) {
            checkedExternalSecret()
                .then(() => {
                    commit('setIsConfigedSecret', true);
                })
                .catch((err) => {
                    commit('setIsConfigedSecret', false);
                    console.error(err);
                });
        },
        resetData(context) {
            context.commit('setUserList', []);
            context.commit('setFormList', {});
            context.commit('setWebpageData', {});
            context.commit('setTagList', []);
            context.commit('setTagName', []);
        },
        getExpireInfo({ commit, dispatch }) {
            return getExpireInfo()
                .then(({ data: { wework_version = 1, expire_time = '' } }) => {
                    commit('setIsBuyWework', wework_version === 2);
                    commit('setExpireTime', expire_time || '');
                    // 兼容safari
                    const timeLeft = expire_time?.replace(/-/gi, '/') || '';
                    const remaining_seconds = new Date(timeLeft) - new Date();
                    commit('setRemainingSeconds', remaining_seconds || 0);
                    remaining_seconds <= 0 && dispatch('getManagerList');
                })
                .finally(() => {
                    commit('setIsLoadExpireTime', true);
                });
        },
        getManagerList({ commit }) {
            getManagerList({ is_available: 1, page: 1, page_size: 3, party_id: 1, role_id: 'a', search: '' }).then(
                (res) => {
                    const list = res?.data?.data || [];
                    const manager = list.map((item) => item.name);
                    commit('setManager', manager);
                }
            );
        }
    },
    modules: {}
});
