import store from '@/store';
import { openExpireTimeDialog } from '@/utils/tools';

// 服务使用权限
const handlerPermits = function (el, binding) {
    const modifiers = binding.modifiers?.service;
    el.addEventListener(
        'click',
        (e) => {
            const { remainingSeconds } = store.state;
            if (modifiers && remainingSeconds <= 0) {
                openExpireTimeDialog();
                e.stopPropagation();
            }
        },
        true
    );
};
const permits = {
    inserted: function (el, binding, vNode) {
        handlerPermits(el, binding, vNode);
    },
    componentUpdated: function (el, binding, vNode) {
        handlerPermits(el, binding, vNode);
    }
};

export default permits;
