const getCookie = function (val) {
  const cookies = document.cookie;
  const cookieKV = {};

  if (cookies) {
    for (let i of cookies.split("; ")) {
      let item = i.split("=");
      cookieKV[item[0].trim()] = item[1];
    }
  }

  if (val in cookieKV) {
    return cookieKV[val];
  }
};

const setCookie = function (name, value) {
  const Days = 30;
  const exp = new Date();
  exp.setTime(exp.getTime() + Days * 24 * 60 * 60 * 1000);
  document.cookie =
    name +
    "=" +
    escape(value) +
    ";expires=" +
    exp.toGMTString() +
    ";domain=.xiaoe-tech.com;path=/";
};

module.exports = {
  getCookie,
  setCookie,
};
