import defaultAvatar from './default-avatar';
import fixIPhoneX from './fix-iphonex';
import permits from './permits';
// 自定义指令
const directives = {
    defaultAvatar,
    fixIPhoneX,
    permits
};

export default {
    install(Vue) {
        Object.keys(directives).forEach((key) => {
            Vue.directive(key, directives[key]);
        });
    }
};
