<template>
    <div class="empty">
        <img src="@/assets/images/promotion_invalid.png" alt="" />
        <p class="text">{{ errMsg }}</p>
        <p class="manage">
            <span>请联系高级管理员</span>
            <!-- <span v-if="manager">{{ manager }}</span>
            <open-data
                v-else
                :id="rolePermission.super_manage.userid"
                :name="rolePermission.super_manage.name"
            ></open-data> -->
        </p>
    </div>
</template>

<script>
import { mapState } from 'vuex';
// import openData from '@/components/openData';

export default {
    components: {
        // openData
    },
    props: {
        errMsg: {
            type: String,
            default: '当前账号暂无使用权限'
        },
        manager: {
            type: String,
            default: ''
        }
    },
    computed: {
        ...mapState(['rolePermission']),
        managerInfo() {
            const { manager } = this.$route.query;
            return this.manager ? this.manager : manager;
        }
    }
};
</script>
<style lang="scss" scoped>
.empty {
    position: absolute;
    top: 43%;
    left: 50%;
    transform: translate(-50%, -50%);
    text-align: center;
    width: 5rem;
    img {
        width: 2.88rem;
        height: 2.88rem;
        text-align: center;
    }
    .text {
        text-align: center;
        color: #999999;
        font-size: 0.28rem;
    }
    .manage {
        margin-top: 0.28rem;
        color: #333333;
    }
}
</style>
